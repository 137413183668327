import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslocoService } from '@jsverse/transloco';

export interface FullLocation {
  longitude: number;
  latitude: number;
  localityLanguage: string;
  continent: string;
  countryName: string;
  countryCode: string;
  principalSubdivision: string;
  city: string;
  postCode: string;
}

@Injectable({
  providedIn: 'root'
})
export class GeoLocationService {
  userGeoLocation$: BehaviorSubject<{longitude: number, latitude: number}> = new BehaviorSubject<{longitude: number; latitude: number}>({longitude: 0, latitude: 0})
  userCityLocation$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  userFullLocation$: BehaviorSubject<any> = new BehaviorSubject<string>('');
  watchListenerId:number = null;

  constructor(
    private httpClient: HttpClient,
    private translocoService: TranslocoService
  ) { }

  getCityNameAsync(longitude: number, latitude: number){
    const lang = this.translocoService.getActiveLang();
    return this.httpClient.get(`https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=${lang}`);
  }


  manuallySetLocation(latitude, longitude, city): void {
    this.stopLocationListener();
    this.userGeoLocation$.next({
      latitude,longitude
    });

    this.userCityLocation$.next(city);
  }

  startLocationListener(): void{
    if (navigator.geolocation) {
      this.watchListenerId = navigator.geolocation.watchPosition((position) => {
        this.userGeoLocation$.next({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });

        this.getCityNameAsync(position.coords.longitude, position.coords.latitude).subscribe((result:any) => {
          this.userCityLocation$.next(result.city);
          this.userFullLocation$.next({
            longitude: result.longitude,
            latitude: result.latitude,
            localityLanguage: result.localityLanguage,
            continent: result.continent,
            countryName: result.countryName,
            countryCode: result.countryCode,
            principalSubdivision: result.principalSubdivision,
            city: result.city,
            postCode: result.postCode,
          })
        });
      }, (error: any) => {
        console.error('GeoLocationService Error:', error);
      }, {
        enableHighAccuracy: true,
        timeout: 8000,
        maximumAge: 0
      });
    } else {
      console.log('No support for geolocation');
    }
  }

  private stopLocationListener(): void {
    if (navigator.geolocation && this.watchListenerId) {
      navigator.geolocation.clearWatch(this.watchListenerId);
    }
  }

}
