import {
  afterNextRender,
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-google-autocomplete',
  standalone: true,
  imports: [
    FormsModule,
  ],
  templateUrl: './google-autocomplete.component.html',
  styleUrl: './google-autocomplete.component.scss'
})
export class GoogleAutocompleteComponent implements OnInit {
  @Input() adressType: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @ViewChild('addresstext') addresstext: any;

  autocompleteInput: string;
  queryWait: boolean;

  constructor(
  ) {
    afterNextRender(async () => {
      this.getPlaceAutocomplete();
    });
  }

  ngOnInit() {
  }

  private getPlaceAutocomplete() {
    // @ts-ignore
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        componentRestrictions: { country: 'AT' },
        types: [this.adressType]  // 'establishment' / 'address' / 'geocode'
      });
    // @ts-ignore
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this.invokeEvent(place);
    });
  }

  invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }

}
