<div class='flex flex-column flex-auto'>
  <div class='surface-section px-4 py-4 lg:py-5 lg:px-6 h-full '>
    <div class='flex flex-column md:flex-row w-full justify-content-between md:align-items-center'>
      <div>
        <h2 class='mt-0 mb-2 text-900 font-medium text-2xl'>Settings</h2>
        <p class='mt-0 mb-0 text-500'>Vivamus id nisl interdum, blandit augue sit amet, eleifend mi.</p>
      </div>
    </div>
    <p-divider styleClass='my-5'></p-divider>

    <div class='p-fluid flex flex-column lg:flex-row'>
      <ul
        class='list-none m-0 p-0 flex flex-row lg:flex-column justify-content-between lg:justify-content-start mb-5 lg:mb-0'>
        <li>
          <a pRipple
             class='lg:w-15rem flex align-items-center cursor-pointer p-3 border-round hover:surface-200 transition-duration-150 transition-colors'
             [routerLink]="['/user','account']" [routerLinkActive]="'surface-200'">
            <i class='pi pi-user md:mr-2 ' [routerLinkActive]="'text-800'" ></i>
            <span class='font-medium hidden md:block' [routerLinkActive]="'text-800'">Account</span>
          </a>
        </li>
        <li>
          <a pRipple
             class='lg:w-15rem flex align-items-center cursor-pointer p-3 border-round hover:surface-200 transition-duration-150 transition-colors'
              [routerLink]="['/user','notifications']" [routerLinkActive]="'surface-200'">
            <i class='pi pi-share-alt md:mr-2' [routerLinkActive]="'text-800'" ></i>
            <span class='font-medium hidden md:block' [routerLinkActive]="'text-800'">Integrations</span>
          </a>
        </li>
<!--        <li>
          <a pRipple
             class='lg:w-15rem flex align-items-center cursor-pointer p-3 border-round hover:surface-200 transition-duration-150 transition-colors'
             [routerLink]="['/user','rewards']" [routerLinkActive]="'surface-200'">
            <i class='pi pi-gift md:mr-2' [routerLinkActive]="'text-800'" ></i>
            <span class='font-medium hidden md:block' [routerLinkActive]="'text-800'">Rewards</span>
          </a>
        </li>-->
        <p-divider class='hidden lg:block'></p-divider>
        <li>
          <a pRipple
             class='lg:w-15rem flex align-items-center cursor-pointer p-3 border-round hover:surface-200 transition-duration-150 transition-colors'
             [routerLink]="['/user','likes']" [routerLinkActive]="'surface-200'">
            <i class='pi pi-heart md:mr-2 ' [routerLinkActive]="'text-800'" ></i>
            <span class='font-medium hidden md:block' [routerLinkActive]="'text-800'">Likes</span>
          </a>
        </li>
        <!--<li>
          <a pRipple
             class='lg:w-15rem flex align-items-center cursor-pointer p-3 border-round hover:surface-200 transition-duration-150 transition-colors'
             [routerLink]="['/user','bookmarks']" [routerLinkActive]="'surface-200'">
            <i class='pi pi-bookmark md:mr-2 ' [routerLinkActive]="'text-800'" ></i>
            <span class='font-medium hidden md:block' [routerLinkActive]="'text-800'">Bookmarks</span>
          </a>
        </li>-->
      </ul>
      <div class='surface-card p-5 shadow-2 border-round flex-auto xl:ml-5'>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<p-toast position="top-right" key="confirm" (onClose)="onReject()" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class="flex flex-column align-items-start" style="flex: 1">
      <div class="flex align-items-center gap-2">
        <span class="font-bold text-black-alpha-90">Important</span>
      </div>
      <div class="font-medium text-lg my-3 text-black-alpha-90">{{ message.summary }}</div>
      <p-button class="p-button-sm" label="Okay" (click)="onConfirm()"></p-button>
    </div>
  </ng-template>
</p-toast>
