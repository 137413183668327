import { afterNextRender, AfterViewInit, Component, OnInit } from '@angular/core';
import {AuthService} from "../../../auth/auth.service";
import { BehaviorSubject, debounceTime, fromEvent, Observable, Subject } from 'rxjs';
import {Router} from "@angular/router";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { User, UserService } from '@wasmachen/api-connector';
import { GeoLocationService } from '../../services/geo-location.service';

@UntilDestroy()
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, AfterViewInit {
  isMobile: boolean = false;
  visible1 = false;
  isLoggedIn = new BehaviorSubject<string>('');
  showGoogleMapsSearch = false;

  user$: Observable<User> = null;
  userGeoLocation$: Observable<{longitude: number, latitude: number}> = null;
  currentUserGeo = null;
  currentLocation = '';
  constructor(
    private authService: AuthService,
    private router: Router,
    private locationService: GeoLocationService,
  ) {
    afterNextRender(async () => {
      await this.authService.checkForSession();
      if (window?.innerWidth <= 992) {
        this.isMobile = true;
      }
      fromEvent(window, 'resize')
        .pipe(untilDestroyed(this), debounceTime(150))
        .subscribe(() => {
          this.updateSize();
        });
      this.userGeoLocation$ = this.locationService.userGeoLocation$;
    });
  }

  ngOnInit() {
    this.currentLocation = this.locationService.userCityLocation$.value;
    this.currentUserGeo = this.locationService.userGeoLocation$.value;

    this.locationService.userCityLocation$.subscribe((city) => {

      this.currentLocation = city;

      if(!this.currentLocation){
        this.showGoogleMapsSearch = true;
      }
    });

    this.locationService.userGeoLocation$.subscribe((location) =>{
      this.currentUserGeo = location;
    });

  }

  async ngAfterViewInit() {
    this.authService.userId$.subscribe((sessionId) => {
      this.isLoggedIn.next(sessionId ? sessionId : '');
    });
    this.user$ = this.authService.user$;
  }

  changeLocation(){
    this.showGoogleMapsSearch = true;
  }

  setAddress(data){
    this.locationService.manuallySetLocation(data.geometry.location.lat(), data.geometry.location.lng(), data.name);
    this.showGoogleMapsSearch = false;
    // @ts-ignore
    document.querySelector("#distance").click();
  }

  redirectToLogin() {
    window.location.href = "auth";
  }

  updateSize() {
    this.isMobile = window?.innerWidth <= 992;
  }

  async onLogout() {
    await this.authService.signOut();
    window.location.reload();
  }

}
